import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CCreateElement,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDropdown,
  CSidebarMinimizer,
} from "@coreui/react";
import { CSidebarNavTitle, CSidebarNavItem } from "@coreui/react";
import logo from "../assets/img/brand/MSM-white.png";
import CIcon from "@coreui/icons-react";
import React from "react";

interface Props {
  menu: any;
}
const TheSidebar = ({ menu }: Props) => {
  const dispatch = useDispatch();
  const show = useSelector((state: any) => state.sidebarShow);

  return (
    <CSidebar
      show={show}
      onShowChange={(val: any) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none pos_rel" to="/">
        <div style={{ marginTop: "50px" }}>
          <CIcon className="c-sidebar-brand-full" src={logo} height={45} />
        </div>
        <div style={{ marginTop: "20px" }}>
          <CIcon
            className="c-sidebar-brand-minimized marginBottom-10"
            src={logo}
            height={15}
          />
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={menu}
          components={{
            CSidebarNavItem,
            CSidebarNavTitle,
            CSidebarNavDropdown,
          }}
        ></CCreateElement>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
