import { Fecth } from "@tools/fecth/fecth";
// import { apiVersion } from "../../config/api";

export interface userLogin {
  username: string;
  password: string;
}

export const userLoginAPT = async (data: userLogin) => {
  const res = await Fecth({
    data: data,
    method: "POST",
    path: `/api/auth/signin`,
    isBasic: true,
  });
  return res;
};

export const userLogout = async () => {
  const res = await Fecth({
    method: "POST",
    path: "/api/auth/signout",
    alert: false,
  });
  return res;
};
