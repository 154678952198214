import { fecthConfig } from "./interface";
import { apiURL } from "../../config/api";
import { resResult } from "../constain";
import Swal from "sweetalert2";
import { notifications } from "./notifications";
import { _isEmpty } from "@tools/util";

export const Fecth = async <T extends { code: number }>(
  fecthConfig: fecthConfig
) => {
  try {
    const url = `${apiURL}${fecthConfig.path}`;
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: fecthConfig.method,
      body:
        fecthConfig.method === "PATCH" ||
        fecthConfig.method === "POST" ||
        fecthConfig.method === "DELETE"
          ? JSON.stringify(fecthConfig.data)
          : null,
    });

    if (res.headers.get("content-type") === "application/pdf") {
      const _res = await parseResponse(res);

      return _res;
    }
    const dataResponse = await res.json();
    if (dataResponse.code === 401 && fecthConfig.path === "/api/auth/signin") {
      Swal.fire({
        icon: "warning",
        title: `${dataResponse.code} ${dataResponse.status}`,
        text: `โปรดตรวจสอบ Username และ Password`,
        timer: 1500,
        timerProgressBar: true,
      });
    } else if (dataResponse.code === 401) {
      const newData: any = await renewToken(fecthConfig);
      return newData;
    }
    if (fecthConfig.alert !== false) {
      notifications(dataResponse, fecthConfig);
    }
    return {
      ...dataResponse,
      result: _isEmpty(dataResponse.result) ? [] : dataResponse.result,
    } as T;
  } catch (error) {
    return { res: null, message: error };
  }
};

const renewToken = async (fecthConfig: fecthConfig) => {
  const url = `${apiURL}/api/auth/token/renew`;
  const refreshToken = localStorage.getItem("refreshToken");
  const resNew = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
    method: "POST",
  });
  const dataResponse = await resNew.json();
  if (dataResponse.code === resResult.sucess) {
    localStorage.setItem("token", dataResponse.result.accessToken);
    const result = await Fecth(fecthConfig);

    return result;
  } else {
    localStorage.clear();
  }
  return dataResponse;
};

export const FetchFile = async (fecthConfig: fecthConfig) => {
  try {
    const url = `${apiURL}${fecthConfig.path}`;
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: fecthConfig.method,
      body:
        fecthConfig.method === "PATCH" || fecthConfig.method === "POST"
          ? JSON.stringify(fecthConfig.data)
          : null,
    });
    if (res.status === resResult.sucess) {
      const _res = await parseResponse(res);
      return _res;
    }
    throw new Error();
  } catch (err) {
    throw err;
  }
};

const parseResponse = async (res: any) => {
  const _res = await res.blob();
  return _res;
};

export const fetchFormData = async (fecthConfig: fecthConfig) => {
  try {
    const url = `${apiURL}${fecthConfig.path}`;
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
      method: fecthConfig.method,
      body: fecthConfig.formData,
    });
    if (
      res.headers.get("content-type") ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return { code: 200 };
    }
    const _data = await res.json();
    return _data;
  } catch (err) {
    return { success: false, message: err };
  }
};
