import { minusNumber, plusNumber } from "@calculates/simples";
import moment, { Moment } from "moment";
import Swal from "sweetalert2";
import { IInvoiceGet, Item } from "./interfaces/invoice";
import { IUnitGET } from "./interfaces/units";
import { nameBank, typeUnit } from "./constain";

export const _isEmpty = (data: any = {}) => {
  const queryDefault =
    data === null || data === "undefined" || data === undefined || data === "";
  if (queryDefault) return true;
  if (typeof data === "number") return false;
  if (typeof data === "string") return false;
  const obj = queryDefault ? [] : data;
  return Object.entries(obj).length === 0;
};

export const createQueryString = (values: any) => {
  if (!values) {
    return "";
  }
  let query = "";
  let result = {};
  for (const [key, value] of Object.entries(values)) {
    if (value || value === 0 || value === false) {
      result = { ...result, [key]: value };
    }
  }
  Object.entries(result).forEach(([key, value], index) => {
    if (index === Object.keys(result).length - 1) {
      if (value || value === 0 || value === false) {
        query += `${key}=${value}`;
      }
    } else {
      if (value || value === 0 || value === false) {
        query += `${key}=${value}&`;
      }
    }
  });
  return query;
};

export const justNumbers = (data: string) => {
  var numsStr = data.replace(/[^0-9]/g, "");
  return numsStr;
};

export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const dummyRequest = ({ _, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const downloadFileExcel = (
  blobFile: any,
  filename: string = "Excel.xlsx"
) => {
  const url = window.URL.createObjectURL(new Blob([blobFile]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const downloadFilePDF = (blobFile: any, name: string) => {
  const url = window.URL.createObjectURL(new Blob([blobFile]));
  const link = document.createElement("a");
  link.setAttribute("download", `${name}.pdf`); //or any other extension
  link.href = url;
  document.body.appendChild(link);
  link.click();
};

export const afterNow = (dueDate: string) => {
  const a = moment().diff(dueDate, "days");
  if (a < 0) {
    return 0;
  }
  return Number(a);
};

export const sumItem = (value: Item[] | any) => {
  let amount = 0;
  value.forEach((item: any) => (amount += Number(item.amount) || 0));
  return amount;
};

export const checkFile = (file: any) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isJpgOrPng) {
    Swal.fire({
      icon: "warning",
      title: `ประเภทไฟล์ต้องเป็น JPG/PNG`,
      text: `โปรดตรวจสอบไฟล์ข้อมูลแล้วลองใหม่อีกครั้ง`,
      timer: 1500,
      timerProgressBar: true,
    });
  }
  if (!isLt2M) {
    Swal.fire({
      icon: "warning",
      title: `ขนาดไฟล์ต้องไม่เกิน 2 MB`,
      text: `โปรดตรวจสอบไฟล์ข้อมูลแล้วลองใหม่อีกครั้ง`,
      timer: 1500,
      timerProgressBar: true,
    });
  }
  return isJpgOrPng && isLt2M;
};

export const summaryInvoice = (selectedRows: IInvoiceGet[]) => {
  return selectedRows
    .filter((item) => {
      return ["OPEN", "PARTIAL"].includes(item.status);
    })
    .reduce((pre, cur) => {
      const a = minusNumber(
        Number(cur.amountNet),
        Number(cur.amountCreditNote || 0)
      );
      const b = minusNumber(
        Number(cur.paymentAmount),
        Number(cur.amountCreditNote || 0)
      );
      return plusNumber(
        pre,
        plusNumber(minusNumber(a, b), Number(cur.fine) || 0)
      );
    }, 0);
};

export const logoBank = (name: string) => {
  const data = nameBank.find((item) => name === item.name);
  if (_isEmpty(data)) {
    return `${window.location.origin}/assets/image/bank/scb.svg`;
  }
  return `${window.location.origin}/assets/image/bank/${data?.img}`;
};

export const disabledDate = (current: Moment) => {
  return current && current > moment().endOf("day");
};

export const getRageYear = (value: any) => {
  if (moment(value[0]).format("YYYY") !== moment(value[1]).format("YYYY")) {
    return `${moment(value[0]).format("YYYY")} - ${moment(value[1]).format(
      "YYYY"
    )}`;
  }
  return moment(value[1]).format("YYYY");
};

export const arrayYear = (value: any) => {
  if (moment(value[0]).format("YYYY") !== moment(value[1]).format("YYYY")) {
    const dateRage = minusNumber(
      Number(moment(value[1]).format("YYYY")),
      Number(moment(value[0]).format("YYYY"))
    );
    const arr = [];
    for (let i = 0; i <= dateRage; i++) {
      arr.push(Number(moment(value[0]).format("YYYY")) + i);
    }
    return arr;
  }
  return Number(moment(value[1]).format("YYYY"));
};
export const provinceUniq = (value: any[]) => {
  const uniq = value.filter(
    (value: any, index: number, self: any[]) =>
      index === self.findIndex((t) => t.province === value.province)
  );
  return uniq;
};

export const unitUniq = (value: IUnitGET[]) => {
  const uniq = value.filter(
    (value: IUnitGET, index: number, self: IUnitGET[]) =>
      index === self.findIndex((t) => t.unitNumber === value.unitNumber)
  );
  return uniq;
};

export const compareRoleMenu = (a: any, b: any) => {
  if (a.nameEN < b.nameEN) {
    return -1;
  }
  if (a.nameEN > b.nameEN) {
    return 1;
  }
  return 0;
};

export const roomType = (type: string) => {
  const res = typeUnit.find((item) => item.en === type);
  if (res) return res.th;
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const sleep = (sec = 2) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, sec * 1000);
  });
};
