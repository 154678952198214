import PropertiesMenu from "../../layout/Menu";
import { atom } from "recoil";
import routers from "../../routes/router";
import { recoilPersist } from "recoil-persist";
import moment from "moment";
import { MeterFindAllQueryInterface } from "@api/meter";
import { IQueryCreditNoteAtom } from "@interfaces/credit";
import { IQueryAccrual } from "@interfaces/accrual";

const { persistAtom } = recoilPersist();

export const title = atom({
  key: "title",
  default: {
    titleTop: "",
    title: "",
  },
});

export const routerState = atom({
  key: "router",
  default: routers,
});

export const menuState = atom({
  key: "menu",
  default: PropertiesMenu,
});

export const loadingState = atom({
  key: "loading",
  default: false,
});

export const loadingPageState = atom({
  key: "loadingPageState",
  default: false,
});

export const selectInvoice = atom({
  key: "selectInvoice",
  default: [],
});

export const queryInvoice = atom({
  key: "queryInvoice",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryReceipt = atom({
  key: "queryReceipt",
  default: {
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  },
  effects_UNSTABLE: [persistAtom],
});

export const queryCreditNote = atom<IQueryCreditNoteAtom>({
  key: "queryCreditNote",
  default: {
    page: 1,
    limit: 10,
  },
  effects_UNSTABLE: [persistAtom],
});

export const queryProperty = atom({
  key: "queryProperty",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryWarning = atom<{
  propertyId?: number;
  unitId?: number;
  status?: string;
  dateOfIssueStart?: string;
  dateOfIssueEnd?: string;
  page?: number;
  limit?: number;
}>({
  key: "queryWarning",
  default: {
    page: 1,
    limit: 10,
  },
  effects_UNSTABLE: [persistAtom],
});

export const queryListKey = atom({
  key: "queryListKey",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryFine = atom({
  key: "queryFine",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryUnit = atom({
  key: "queryUnit",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryMeter = atom<
  MeterFindAllQueryInterface & {
    month?: string;
    compareMonth?: [string, string];
  }
>({
  key: "queryMeter",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryMeterReading = atom({
  key: "queryMeterReading",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryResident = atom({
  key: "queryResident",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryAdmin = atom({
  key: "queryAdmin",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryAccrual = atom<IQueryAccrual>({
  key: "queryAccrual",
  default: {
    page: 1,
    limit: 10,
  },
  effects_UNSTABLE: [persistAtom],
});

export const queryDebtfree = atom({
  key: "queryDebtFree",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const queryAR = atom({
  key: "queryAR",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
