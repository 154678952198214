import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CToggler } from "@coreui/react";
import { title } from "../tools/recoil/recoil";
import { useRecoilState } from "recoil";
import { Col, Popconfirm, Row } from "antd";
import { useHistory } from "react-router";
import { IUserInfo } from "@interfaces/login";
import { userLogout } from "@api/auth";
import { resResult } from "@tools/constain";
import logout from "@assets/img/logout.png";

const LogoutButton = () => {
  const history = useHistory();

  const handleLogout = async () => {
    try {
      const res = await userLogout();
      if (res.code === resResult.sucess) {
        history.push("/login");
      }
    } catch (error) {
    } finally {
      history.push("/login");
    }
  };
  return (
    <Popconfirm
      placement="bottomRight"
      title="คุณต้องการออกจากระบบหรือไม่?"
      onConfirm={handleLogout}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
      okButtonProps={{
        style: { width: 60, height: 30 },
      }}
      cancelButtonProps={{
        style: { width: 60, height: 30 },
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          padding: "15px",
          height: "60px",
          borderRadius: "4px",
          cursor: "pointer",
          transition: "0.3s ease-out",
          boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
        }}
      >
        <img
          src={logout}
          style={{
            width: 30,
          }}
          alt="logout"
        />
      </div>
    </Popconfirm>
  );
};

const TheHeader = (props: any) => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state: any) => state.sidebarShow);
  const [titlePage] = useRecoilState(title);
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const history = useHistory();

  useEffect(() => {
    getUserInfo();
  }, [sidebarShow]);

  const getUserInfo = () => {
    const data = localStorage.getItem("userInfo");
    if (data) {
      setUserInfo(JSON.parse(data));
    }
  };
  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  return (
    <div
      style={{
        paddingLeft: "35px",
        paddingRight: "35px",
        marginBottom: "35px",
      }}
    >
      <Row align="middle">
        <Col className="title-header" span={12}>
          <p>{titlePage.titleTop}</p>
          <h1>{titlePage.title}</h1>
        </Col>

        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />
        <Col span={12}>
          <div>
            <Row justify="end" gutter={10}>
              <Col>
                <div
                  style={{
                    backgroundColor: "#FFF",
                    padding: "15px",
                    height: "60px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    transition: "0.3s ease-out",
                    boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
                  }}
                  onClick={() => {
                    history.push("/profile");
                  }}
                >
                  <h4>{userInfo?.fullname}</h4>
                </div>
              </Col>
              <Col>
                <LogoutButton />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TheHeader;
