import BigNumber from "bignumber.js";

import numeral from "numeral";
import { afterNow } from "../tools/util";
// (x+y)
export const plusNumber = (x: number, y: number) => {
  const a = new BigNumber(x);
  const b = new BigNumber(y);
  const total = a.plus(b);
  return Number(total);
};

// (multiplicand*multiplier)
export const multiplierNumber = (multiplicand: number, multiplier: number) => {
  const a = new BigNumber(multiplicand);
  const b = new BigNumber(multiplier);
  const total = a.multipliedBy(b);
  return Number(total);
};

// (number-subtraction)
export const minusNumber = (
  number: number | string,
  subtraction: number | string
) => {
  const a = new BigNumber(Number(number));
  const b = new BigNumber(Number(subtraction));
  const total = a.minus(b);
  if (Number(total) < 0) {
    return 0;
  }
  return Number(total);
};

// (dividend/divider)
export const divideNumber = (dividend: number, divider: number) => {
  const a = new BigNumber(dividend);
  const b = new BigNumber(divider);
  const total = a.dividedBy(b);
  return Number(total);
};

// (percent/100)*amount คำนวนหาค่าที่ลดจากเปอร์เซน
export const discountPercent = (percent: number, amount: number) => {
  const a = new BigNumber(percent);
  const b = new BigNumber(amount);
  const total = a.dividedBy(100).multipliedBy(b);
  return Number(total);
};

// (discount/amount)*100 คำนวนหาเปอร์เซนลดจากจำนวนเงิน
export const findDiscountPercent = (discount: number, amount: number) => {
  const a = new BigNumber(discount);
  const b = new BigNumber(amount);
  const total = a.dividedBy(b).multipliedBy(100);
  return Number(total);
};

export const twoDecimal = (number: number | string | undefined) => {
  const a = new BigNumber(number || 0);
  return Number(a.decimalPlaces(2));
};

export const SumFine = (
  amountNet: number | string,
  day: number,
  percentFind: number
) => {
  const amountPercent = discountPercent(Number(amountNet), percentFind);
  const amoutPerDay = divideNumber(amountPercent, 365);
  return multiplierNumber(amoutPerDay, day);
};

export const sumFineString = (
  amountNet: number | string,
  day: string,
  find: { firstRat: number; secondRate: number; numberDay: number }
) => {
  const dayNumber = afterNow(day);
  if (dayNumber === 0) {
    return;
  } else if (dayNumber >= Number(find.numberDay)) {
    const amountPercent = discountPercent(
      Number(amountNet),
      Number(find?.secondRate)
    );
    const amoutPerDay = divideNumber(amountPercent, 365);
    return ` + ${numeral(multiplierNumber(amoutPerDay, dayNumber)).format(
      "0,0.00"
    )}`;
  } else if (dayNumber < Number(find?.numberDay)) {
    const amountPercent = discountPercent(
      Number(amountNet),
      Number(find?.firstRat)
    );
    const amoutPerDay = divideNumber(amountPercent, 365);
    return ` + ${numeral(multiplierNumber(amoutPerDay, dayNumber)).format(
      "0,0.00"
    )}`;
  }
  return "";
};

export const indexData = (
  limit: number | string | undefined,
  page: number | string | undefined,
  index: number | string | undefined
) => {
  const limitBig = new BigNumber(limit || 0);
  const pageBig = new BigNumber(page || 0);
  const indexBig = new BigNumber(index || 0);

  const first = limitBig.multipliedBy(pageBig.minus(1)).decimalPlaces(0);
  const second = first.plus(indexBig.plus(1));

  return Number(second);
};

export const commonFee = (
  rate: number | string | undefined,
  sizeRoom: number | string | undefined
) => {
  const rateBig = new BigNumber(rate || 1);
  const sizeRoomBig = new BigNumber(sizeRoom || 1);
  const month = new BigNumber(1 / 12);
  const allMounth = sizeRoomBig.multipliedBy(rateBig);
  const aMonth = month.multipliedBy(allMounth);
  return Number(aMonth);
};

export const amountPerQty = (
  rate: number | string | undefined,
  sizeRoom: number | string | undefined
) => {
  const rateBig = new BigNumber(rate || 1);
  const sizeRoomBig = new BigNumber(sizeRoom || 1);
  const ans = rateBig.multipliedBy(sizeRoomBig);
  return Number(ans);
};

export const discountPrice = (
  qty: number,
  amountPerQty: number,
  percent: number
) => {
  const amount = multiplierNumber(qty, amountPerQty);
  const amoutDiscount = discountPercent(percent, Number(amount));
  const sum = minusNumber(Number(amount), Number(amoutDiscount));
  return Number(sum);
};
