import dashboard from "../assets/img/icon/dashboard.svg";
import home from "../assets/img/icon/home.svg";
import setting from "../assets/img/icon/cogwheel.svg";
import clipboard from "../assets/img/icon/clipboard.svg";
import user from "../assets/img/icon/user.svg";
import receipt from "../assets/img/icon/receipt.svg";
import contact from "../assets/img/icon/contact_page_black.svg";
import chart from "../assets/img/icon/chart-bar.svg";
import credit from "@icon/credit.svg";

const PropertiesMenu = [
  {
    _tag: "CSidebarNavTitle",
    nameEn: "all",
    _children: [""],
  },

  {
    _tag: "CSidebarNavItem",
    name: "เดชบอร์ด",
    nameEn: "dashboard",
    icon: (
      <img src={dashboard} alt="dashboard" className="c-sidebar-nav-icon"></img>
    ),
    to: `/dashboard`,
  },
  {
    _tag: "CSidebarNavItem",
    name: "ใบแจ้งหนี้",
    nameEn: "invoice",
    to: `/invoice`,
    icon: (
      <img src={receipt} alt="receipt" className="c-sidebar-nav-icon"></img>
    ),
  },

  {
    _tag: "CSidebarNavItem",
    name: "ใบเสร็จรับเงิน",
    nameEn: "receipt",
    to: `/receipt`,
    icon: (
      <img src={contact} alt="contact" className="c-sidebar-nav-icon"></img>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "ใบลดหนี้",
    nameEn: "credit notes",
    to: `/credit-note`,
    icon: <img src={credit} alt="receipt" className="c-sidebar-nav-icon"></img>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "ใบเตือน",
    nameEn: "warning",
    to: `/warning`,
    icon: <img src={credit} alt="receipt" className="c-sidebar-nav-icon"></img>,
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "ทะเบียนลูกหนี้",
    nameEn: "accounts receivable",
    icon: <img src={credit} alt="receipt" className="c-sidebar-nav-icon"></img>,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: `จัดการลูกหนี้`,
        to: `/accounts-receivable`,
      },
      {
        _tag: "CSidebarNavItem",
        name: `ใบปลอดหนี้`,
        to: `/debt-free`,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "ทะเบียน",
    nameEn: "register",
    icon: <img src={home} alt="home" className="c-sidebar-nav-icon"></img>,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: `ทะเบียนโครงการ`,
        to: `/properties`,
      },
      {
        _tag: "CSidebarNavItem",
        name: `ทะเบียนห้อง`,
        to: `/units`,
      },
      {
        _tag: "CSidebarNavItem",
        name: `ทะเบียนมิเตอร์น้ำ`,
        to: `/meter`,
      },
      {
        _tag: "CSidebarNavItem",
        name: `จดมิเตอร์น้ำ`,
        to: `/meter-reading`,
      },
      {
        _tag: "CSidebarNavItem",
        name: `ทะเบียนผู้อยู่อาศัย`,
        to: `/residents`,
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "ทะเบียนผังบัญชี",
    nameEn: "accounting",
    icon: (
      <img src={clipboard} alt="chart" className="c-sidebar-nav-icon"></img>
    ),
    to: `/`,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: `จัดการเงินคงค้าง`,
        to: `/accrual`,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "รายงาน",
    nameEn: "report",
    icon: <img src={chart} alt="chart" className="c-sidebar-nav-icon"></img>,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "รายวัน",
        to: `/report/dairy`,
      },
      {
        _tag: "CSidebarNavItem",
        name: "การแจ้งค่าใช้จ่ายประจำงวด",
        to: `/report/expenses`,
      },
      {
        _tag: "CSidebarNavItem",
        name: "การวิเคราะห์อายุหนี้",
        to: `/report/aging`,
      },
      {
        _tag: "CSidebarNavItem",
        name: "การวิเคราะห์อายุหนี้แบบละเอียด",
        to: `/report/aging-advance`,
      },
      {
        _tag: "CSidebarNavItem",
        name: "ใบนำส่งเงินรายวัน",
        to: `/report/daily-remittance`,
      },
      {
        _tag: "CSidebarNavItem",
        name: "การแจ้งหนี้รายบุคคล",
        to: `/report/individual-invoice`,
      },
      {
        _tag: "CSidebarNavItem",
        name: "รายงานกระทบยอดบัญชี",
        to: `/report/statement-reconcile`,
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "ผู้ใช้งาน",
    nameEn: "admin",
    icon: <img src={user} alt="user" className="c-sidebar-nav-icon"></img>,
    to: `/admin`,
  },
  {
    _tag: "CSidebarNavItem",
    name: "การตั้งค่า",
    nameEn: "setting",
    icon: (
      <img src={setting} alt="setting" className="c-sidebar-nav-icon"></img>
    ),
    to: `/setting`,
  },
];

export default PropertiesMenu;
