import "./assets/scss/App.scss";
import "./assets/less/variables.less";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "./views/login/LoginPage";
import TheLayout from "./layout/TheLayout";
import { RecoilRoot } from "recoil";
import { _isEmpty } from "./tools/util";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/404" component={() => <div>404</div>} />
        <Route
          exact
          path="/login"
          render={(props) => (
            <RecoilRoot>
              <LoginPage />
            </RecoilRoot>
          )}
        />
        <CheckRoute />
      </Switch>
    </HashRouter>
  );
}

export default App;

const CheckRoute = () => {
  const token = localStorage.getItem("token");
  if (_isEmpty(token)) {
    return <Redirect from="/" to="/login" />;
  }
  return (
    <Route
      path="/"
      render={(props) => (
        <RecoilRoot>
          <TheLayout {...props} />
        </RecoilRoot>
      )}
    />
  );
};
