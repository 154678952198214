import { GET_ME } from "@api/me";
import { GET_ROLE_MENU_BY_ID } from "@api/role-menu";
import { resResult } from "@tools/constain";
import { useEffect } from "react";
import TheContent from "./TheContent";
import TheSidebar from "./TheSidebar";
import Menu from "./Menu";
import router from "../routes/router";
import { useRecoilState } from "recoil";
import { menuState, routerState } from "@tools/recoil/recoil";

const TheLayout = (props: any) => {
  const [menuPermission, setMenuPermission] = useRecoilState(menuState);
  const [routerPermission, setRouterPermission] = useRecoilState(routerState);
  useEffect(() => {
    getAccess();
    // eslint-disable-next-line
  }, []);
  const getAccess = async () => {
    try {
      const me = await GET_ME();
      if (me.code === resResult.sucess) {
        if (me.result.role.title === "Super Admin") {
          setMenuPermission(Menu);
          setRouterPermission(router);
        } else {
          const res = await GET_ROLE_MENU_BY_ID(Number(me.result.role.id));
          const menu = res.result.menus;
          if (res.code === resResult.sucess) {
            const activeMenu = menu
              .map((item: any) => {
                if (item.action || item.view) {
                  return item.menu.nameEN;
                }
                return null;
              })
              .filter((record: any) => record);

            const newMenu = Menu.filter((item) =>
              activeMenu.some(
                (value: string) =>
                  value === item.nameEn || item.nameEn === "all"
              )
            );

            const newRouter = router.filter((item) =>
              activeMenu.some(
                (value: string) => value === item.group || item.group === "all"
              )
            );
            setRouterPermission(newRouter);
            setMenuPermission(newMenu);
          }
        }
      }
    } catch (error) {}
  };
  return (
    <div className="c-app c-default-layout">
      <TheSidebar menu={menuPermission} />
      <div className="c-wrapper">
        <div className="c-body">
          <TheContent router={routerPermission} />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
