import { PaginationInterface } from "@interfaces/common";
import { IMePATCH } from "@interfaces/me";
import { Fecth } from "@tools/fecth/fecth";
import { createQueryString } from "@tools/util";

export const GET_ME = async () => {
  const res = await Fecth({
    method: "GET",
    path: `/api/me`,
  });
  return res;
};

export const PATCH_ME = async (value: IMePATCH) => {
  const res = await Fecth({
    method: "PATCH",
    path: `/api/me`,
    data: value,
  });
  return res;
};

export const GET_MY_TASK = async (query: PaginationInterface) => {
  const querystr = createQueryString(query);
  const res = await Fecth({
    method: "GET",
    path: `/api/me/task?${querystr}`,
    alert: false,
  });
  return res;
};
