import { resResult } from "@tools/constain";
import Swal from "sweetalert2";
import { fecthConfig } from "./interface";
export const notifications = (dataResponse: any, fecthConfig: fecthConfig) => {
  if (
    dataResponse.code === resResult.sucess &&
    fecthConfig.method === "POST" &&
    fecthConfig.path !== "/api/auth/signin"
  ) {
    Swal.fire({
      icon: "success",
      title: "บันทึกสำเร็จ",
      timer: 1500,
      timerProgressBar: true,
    });
  } else if (
    dataResponse.code === resResult.sucess &&
    fecthConfig.method === "PATCH"
  ) {
    Swal.fire({
      icon: "success",
      title: "แก้ไขสำเร็จ",
      timer: 1500,
      timerProgressBar: true,
    });
  } else if (
    dataResponse.code === resResult.sucess &&
    fecthConfig.method === "DELETE"
  ) {
    Swal.fire({
      icon: "success",
      title: "ลบข้อมูลสำเร็จ",
      timer: 1500,
      timerProgressBar: true,
    });
  } else if (
    dataResponse.code !== 200 &&
    fecthConfig.method !== "GET" &&
    dataResponse.code !== 401
  ) {
    Swal.fire({
      icon: "warning",
      title: `${dataResponse.code} ${dataResponse.status}`,
      text: `${dataResponse.message}`,
      timer: 1500,
      timerProgressBar: true,
    });
  } else if (
    fecthConfig.method === "GET" &&
    dataResponse.code !== resResult.sucess
  ) {
    setTimeout(() => {
      Swal.fire({
        icon: "error",
        title: `${dataResponse.code} ${dataResponse.status}`,
        text: `${dataResponse.message}`,
        timerProgressBar: true,
      });
    }, 3000);
  }
};
