import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer } from "@coreui/react";

import { useRecoilState } from "recoil";
import { loadingPageState } from "../tools/recoil/recoil";

import TheHeader from "./TheHeader";
import { Spin } from "antd";

const loading = (
  <Spin>
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  </Spin>
);
interface Props {
  router: any[];
}
const TheContent = ({ router }: Props) => {
  const [loadingPage] = useRecoilState(loadingPageState);
  return (
    <main className="c-main">
      <TheHeader />
      <CContainer fluid>
        <Spin spinning={loadingPage}>
          <Suspense fallback={loading}>
            <Switch>
              {router.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                );
              })}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </Suspense>
        </Spin>
      </CContainer>
    </main>
  );
};

export default React.memo(TheContent);
