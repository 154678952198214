export enum resResult {
  sucess = 200,
  error = 400,
}

export const pagination = { page: 1, limit: 10, pagination: true };
export const typeBank = [
  { id: 1, type: "บัญชีรายวันทั่วไป" },
  { id: 2, type: "บัญชีรายวันซื้อ" },
  { id: 3, type: "บัญชีรายวันขาย" },
  { id: 4, type: "บัญชีเงินสด" },
  { id: 5, type: "บัญชีธนาคาร" },
];

export const nameBank = [
  {
    id: "1",
    name: "กรุงเทพ Bangkok Bank",
    img: "bbl.svg",
  },
  {
    id: "2",
    name: "กรุงไทย Krung Thai Bank",
    img: "ktb.svg",
  },
  {
    id: "3",
    name: "กรุงศรีอยุธยา Bank of Ayudhaya",
    img: "bay.svg",
  },
  {
    id: "4",
    name: "กสิกรไทย Kasikorn Bank",
    img: "kbank.svg",
  },
  {
    id: "5",
    name: "ซิติแบงก์ Citibank",
    img: "citi.svg",
  },
  {
    id: "7",
    name: "ทิสโก้ Thai Investment and Securities Company Bank",
    img: "tisco.svg",
  },
  {
    id: "8",
    name: "ไทยพาณิชย์ Siam Commercial Bank",
    img: "scb.svg",
  },
  {
    id: "9",
    name: "ธนชาติ Thanachart Bank",
    img: "ttb.svg",
  },
  {
    id: "10",
    name: "ยูโอบี United Overseas Bank, Thailand",
    img: "uob.svg",
  },
  {
    id: "11",
    name: "ออมสิน Government Saving Bank",
    img: "gsb.svg",
  },
  {
    id: "12",
    name: "อิสลามแห่งประเทศไทย Islamic Bank of Thailand",
    img: "ibank.svg",
  },
];

export const statusUser = [
  {
    en: "empty",
    th: "ไม่มีห้อง",
  },
  {
    en: "notEmpty",
    th: "มีห้อง",
  },
];

export const unitUserRole = [
  { en: "owner", th: "เจ้าของห้อง" },
  { en: "residents", th: "ผู้อยู่อาศัย" },
  { en: "tenant", th: "ผู้เช่า" },
];

export const typeUnit = [
  {
    en: "resident",
    th: "ห้องพักอาศัย",
  },
  {
    en: "store",
    th: "ห้องร้านค้า",
  },
  {
    en: "special",
    th: "ห้องพิเศษ",
  },
];

export const statusAccrual = [
  { en: "positive", th: "เป็นบวก" },
  { en: "zero", th: "เป็นศูนย์" },
  { en: "negative", th: "เป็นลบ" },
];

export const DEFAULT_PERPAGE = 10;
