import { Fecth } from "@tools/fecth/fecth";

export const GET_ROLE_MENU_BY_ID = async (id: number) => {
  const res = await Fecth({
    method: "GET",
    path: `/api/role-menu/Getmenu?roleId=${id}`,
    alert: false,
  });
  return res;
};
