import React from "react";

const Dashboard = React.lazy(() => import("../views/dashboard/Dashboard"));
const Invoice = React.lazy(() => import("../views/invoice/Invoice"));
const ManageInvoice = React.lazy(() =>
  import("../views/invoice/manage-invoice/ManageInvoice")
);
const InvoicePDF = React.lazy(() =>
  import("../views/invoice/manage-invoice/InvoicePDF")
);
const Receipt = React.lazy(() => import("../views/receipt/Receipt"));
const ManageReceipt = React.lazy(() =>
  import("../views/receipt/manage-receipt/ManageReceipt")
);
const ReceiptPDF = React.lazy(() =>
  import("../views/receipt/manage-receipt/ReceiptPDF")
);

const Unit = React.lazy(() => import("../views/units/Units"));
const ManageUnit = React.lazy(() =>
  import("../views/units/manage-units/ManageUnits")
);

const Properties = React.lazy(() => import("../views/properties/Properties"));
const ManageProperties = React.lazy(() =>
  import("../views/properties/manage-properties/ManageProperties")
);

const Admin = React.lazy(() => import("../views/user-role/Admin"));
const ManageAdmin = React.lazy(() =>
  import("../views/user-role/manage-admin/ManageAdmin")
);

const Resident = React.lazy(() => import("../views/residents/Residents"));
const ManageResident = React.lazy(() =>
  import("../views/residents/ManageResident/ManageResident")
);

const Setting = React.lazy(() => import("../views/setting/Setting"));

const Meter = React.lazy(() => import("../views/meter/Meter"));
const ManageMeter = React.lazy(() =>
  import("../views/meter/manage-meter/ManageMeter")
);

const MeterReading = React.lazy(() =>
  import("../views/meters-reading/MetersReading")
);
const ManageMeterReading = React.lazy(() =>
  import("../views/meters-reading/manage-meters-reading/ManageMeterReading")
);

const Accrual = React.lazy(() => import("../views/accrual/Accrual"));

const Transfer = React.lazy(() => import("../views/transfer/Transfer"));
const ManageTransfer = React.lazy(() =>
  import("../views/transfer/manage-transfer/ManageTransfer")
);

const CreditNote = React.lazy(() => import("../views/credit-note/CreditNote"));
const CreateCreditNote = React.lazy(() =>
  import("../views/credit-note/manage-credit-note/ManageCreditNote")
);
const CreditNotePDF = React.lazy(() =>
  import("../views/credit-note/manage-credit-note/CreditPDF")
);


const Warning = React.lazy(() =>
  import("../views/report/warning/Warning")
);

const ManageWarning = React.lazy(() =>
  import("../views/report/warning/manage-warning/ManageWarning")
)
/** Report */
const DairyReport = React.lazy(() => import("../views/report/dairy-report"));
const ExpensesReport = React.lazy(() => import("../views/report/expenses/Expenses"));
const AgingReport = React.lazy(() => import("../views/report/aging/Aging"));
const AgingAdvance = React.lazy(() => import("../views/report/aging/AgingAdvance"));
const DailyRemittanceReport = React.lazy(() => import("../views/report/daily-remittance/DailyRemittance"));
const IndividualInvoiceReport = React.lazy(() => import("../views/report/individual-invioce/IndividualInvoice"));
const StatementReconcileReport = React.lazy(() => import("../views/report/statement-reconcile"));
/** Report */

/** AR */
const PayOffDebt = React.lazy(() => import("../views/accounts-receivable/AccountReceivable"));
const ManageReceivables = React.lazy(() => import("../views/accounts-receivable/ManageReceivables"));
const Certificate = React.lazy(() => import("../views/accounts-receivable/Certificate"));
const AccountReceivableForm = React.lazy(() => import("../views/accounts-receivable/AccountReceivableForm"));
const DebtFree = React.lazy(() => import("../views/accounts-receivable/DebtFree"))
const DebtFreeForm = React.lazy(() => import("../views/accounts-receivable/DebtFreeForm"));
const DebtFreePdfViewer = React.lazy(() => import("../views/accounts-receivable/DebtFreePdfViewer"))
/** AR */


const routers = [
  { path: "/", exact: true, name: "Home" },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    group: "dashboard",
  },
  //Invoice
  {
    path: "/invoice",
    exact: true,
    name: "Invoice",
    component: Invoice,
    group: "invoice",
  },
  {
    path: "/invoice/manage-invoice",
    exact: true,
    name: "ManageInvoice",
    component: ManageInvoice,
    group: "invoice",
  },
  {
    path: "/invoice/manage-invoice/:id",
    exact: true,
    name: "ManageInvoice",
    component: ManageInvoice,
    group: "invoice",
  },
  {
    path: "/invoice/create-receipt/:idcr",
    exact: true,
    name: "Create Residents",
    component: ManageReceipt,
    group: "invoice",
  },
  {
    path: "/invoice/:idcr/create-credite-note",
    exact: true,
    name: "CreateCreditNote",
    component: CreateCreditNote,
    group: "credit notes",
  },
  {
    path: "/invoice/manage-invoice/:id/pdf",
    exact: true,
    name: "ManageInvoice",
    component: InvoicePDF,
    group: "invoice",
  },
  {
    path: "/invoice/pdf",
    exact: true,
    name: "InvoicePDF",
    component: InvoicePDF,
    group: "invoice",
  },
  {
    path: "/invoice/:id/pdf",
    exact: true,
    name: "InvoicePDF",
    component: InvoicePDF,
    group: "invoice",
  },
  //Receipt
  {
    path: "/receipt",
    exact: true,
    name: "Receipt",
    component: Receipt,
    group: "receipt",
  },
  {
    path: "/receipt/manage-receipt",
    exact: true,
    name: "Receipt",
    component: ManageReceipt,
    group: "receipt",
  },
  {
    path: "/receipt/manage-receipt/:id",
    exact: true,
    name: "Receipt",
    component: ManageReceipt,
    group: "receipt",
  },
  {
    path: "/receipt/manage-receipt/:id/pdf",
    exact: true,
    name: "PDF Receipt",
    component: ReceiptPDF,
    group: "receipt",
  },
  {
    path: "/receipt/pdf",
    exact: true,
    name: "PDFs Receipts",
    component: ReceiptPDF,
    group: "receipt",
  },
  //Unit
  {
    path: "/units",
    exact: true,
    name: "Unit",
    component: Unit,
    group: "register",
  },
  {
    path: "/units/create-units",
    exact: true,
    name: "Create unit",
    component: ManageUnit,
    group: "register",
  },
  {
    path: "/units/edit-units/:id",
    exact: true,
    name: "Edit unit",
    component: ManageUnit,
    group: "register",
  },
  //Properties
  {
    path: "/properties",
    exact: true,
    name: "Properties",
    component: Properties,
    group: "register",
  },
  {
    path: "/properties/create-property",
    exact: true,
    name: "Create Properties",
    component: ManageProperties,
    group: "register",
  },
  {
    path: "/properties/edit-property/:id",
    exact: true,
    name: "ManageProperties",
    component: ManageProperties,
    group: "register",
  },
  //Addmin
  {
    path: "/admin",
    exact: true,
    name: "Admin",
    component: Admin,
    group: "admin",
  },
  {
    path: "/admin/create-admin",
    exact: true,
    name: "Create Admin",
    component: ManageAdmin,
    group: "admin",
  },
  {
    path: "/admin/edit-admin/:id",
    exact: true,
    name: "Edit Admin",
    component: ManageAdmin,
    group: "admin",
  },
  //Resident
  {
    path: "/residents",
    exact: true,
    name: "Resident",
    component: Resident,
    group: "register",
  },
  {
    path: "/residents/create-resident",
    exact: true,
    name: "Create Resident",
    component: ManageResident,
    group: "register",
  },
  {
    path: "/residents/edit-resident/:id",
    exact: true,
    name: "Edit Resident",
    component: ManageResident,
    group: "register",
  },

  //Settings
  {
    path: "/setting",
    exact: true,
    name: "Setting",
    component: Setting,
    group: "setting",
  },
  //Meter
  {
    path: "/meter",
    exact: true,
    name: "Meter",
    component: Meter,
    group: "register",
  },
  {
    path: "/meter/create-meter",
    exact: true,
    name: "Create Meter",
    component: ManageMeter,
    group: "register",
  },
  {
    path: "/meter/edit-meter/:id",
    exact: true,
    name: "Edit Meter",
    component: ManageMeter,
    group: "register",
  },
  //Meter Reading
  {
    path: "/meter-reading",
    exact: true,
    name: "Meter Reading",
    component: MeterReading,
    group: "register",
  },
  {
    path: "/meter-reading/edit-meter-reading/:id",
    exact: true,
    name: "Meter Reading",
    component: ManageMeterReading,
    group: "register",
  },
  {
    path: "/meter-reading/create",
    exact: true,
    name: "Meter Reading",
    component: ManageMeterReading,
    group: "register",
  },
  //Accrual
  {
    path: "/accrual",
    exact: true,
    name: "Accrual Management",
    component: Accrual,
    group: "report",
  },
  //transfer
  {
    path: "/transfer",
    exact: true,
    name: "Arrange Transfer",
    component: Transfer,
    group: "report",
  },
  {
    path: "/transfer/create-transfer",
    exact: true,
    name: "Create Transfer",
    component: ManageTransfer,
    group: "report",
  },

  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: ManageAdmin,
    group: "all",
  },
  //CreditNote
  {
    path: "/credit-note",
    exact: true,
    name: "CreditNote",
    component: CreditNote,
    group: "credit notes",
  },
  {
    path: "/credit-note/create-credit-note",
    exact: true,
    name: "CreateCreditNote",
    component: CreateCreditNote,
    group: "credit notes",
  },
  {
    path: "/credit-note/:id",
    exact: true,
    name: "EditCreditNote",
    component: CreateCreditNote,
    group: "credit notes",
  },
  {
    path: "/credit-note/:id/pdf",
    exact: true,
    name: "CreditNote PDF",
    component: CreditNotePDF,
    group: "credit notes",
  },
  {
    path: "/warning",
    exact: true,
    name: "Warning",
    component: Warning,
    group: "warning",
  },
  {
    path: "/warning/create-warning",
    exact: true,
    name: "Manage Warning",
    component: ManageWarning,
    group: "warning",
  },
  {
    path: "/warning/manage-warning/:id",
    exact: true,
    name: "ManageWarning",
    component: ManageWarning,
    group: "warning",
  },
  {
    path: "/warning/create-warning/:id",
    exact: true,
    name: "Manage Warning",
    component: ManageWarning,
    group: "warning",
  },
  {
    path: "/report/dairy",
    exact: true,
    name: "Dairy report",
    component: DairyReport,
    group: "report",
  },
  {
    path: "/report/expenses",
    exact: true,
    name: "Expenses report",
    component: ExpensesReport,
    group: "report",
  },
  {
    path: "/report/aging",
    exact: true,
    name: "Aging report",
    component: AgingReport,
    group: "report",
  },
  {
    path: "/report/aging-advance",
    exact: true,
    name: "Aging report",
    component: AgingAdvance,
    group: "report",
  },
  {
    path: "/report/daily-remittance",
    exact: true,
    name: "Daily Remittance Report",
    component: DailyRemittanceReport,
    group: "report",
  },
  {
    path: "/report/individual-invoice",
    exact: true,
    name: "Individual Invoice Report",
    component: IndividualInvoiceReport,
    group: "report",
  },
  {
    path: "/report/statement-reconcile",
    exact: true,
    name: "Statement ReconcileReport Report",
    component: StatementReconcileReport,
    group: "report",
  },
  {
    path: "/accounts-receivable",
    exact: true,
    name: "Accounts Receivable",
    component: PayOffDebt,
    group: "accounts-receivable",
  },
  {
    path: "/accounts-receivable/create",
    exact: true,
    name: "Accounts Receivable",
    component: AccountReceivableForm,
    group: "accounts-receivable",
  },
  {
    path: "/accounts-receivable/:id",
    exact: true,
    name: "Accounts Receivable",
    component: AccountReceivableForm,
    group: "accounts-receivable",
  },
  {
    path: "/accounts-receivable",
    exact: true,
    name: "Accounts Receivable",
    component: PayOffDebt,
    group: "accounts-receivable",
  },
  {
    path: "/accounts-receivable/:id/debt-free/create",
    exact: true,
    name: "Debt Free",
    component: DebtFreeForm,
    group: "accounts-receivable",
  },
  {
    path: "/accounts-receivable/:id/debt-free/:dfid/certificate",
    exact: true,
    name: "Debt Free",
    component: Certificate,
    group: "accounts-receivable",
  },
  {
    path: "/debt-free",
    exact: true,
    name: "Debt Free",
    component: DebtFree,
    group: "accounts-receivable",
  },
  {
    path: "/debt-free/:dfid",
    exact: true,
    name: "Debt Free",
    component: DebtFreeForm,
    group: "accounts-receivable",
  },
  {
    path: "/debt-free/:id/pdf",
    exact: true,
    name: "Debt Free",
    component: DebtFreePdfViewer,
    group: "accounts-receivable",
  },
  {
    path: "/accounts-receivable/manage-receivables",
    exact: true,
    name: "Manage Receivables",
    component: ManageReceivables,
    group: "accounts-receivable",
  },
  {
    path: "/accounts-receivable/certificate",
    exact: true,
    name: "Certificate",
    component: Certificate,
    group: "accounts-receivable",
  }
];

export default routers;
