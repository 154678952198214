import { Button, Checkbox, Col, Form, Input, notification, Row } from "antd";
import React, { useEffect } from "react";
import brand from "@brand/MSM.png";
import backdrop from "@assets/img/image1.png";
import user from "@icon/user2.svg";
import key from "@icon/key.svg";
import { userLogin, userLoginAPT } from "@api/auth";
import { resResult } from "@tools/constain";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { loadingState } from "@tools/recoil/recoil";
import { isMobile } from "react-device-detect";
import LoginMobile from "./LoginMobile";
const LoginPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useRecoilState<boolean>(loadingState);
  const [form] = Form.useForm();

  useEffect(() => {
    localStorage.clear();
    // eslint-disable-next-line
  }, []);
  const onFinish = async (valuse: userLogin) => {
    try {
      setLoading(true);
      const res = await userLoginAPT(valuse);
      if (res.code === resResult.sucess) {
        localStorage.setItem("token", res.result.accessToken);
        localStorage.setItem("refreshToken", res.result.refreshToken);
        localStorage.setItem("userInfo", JSON.stringify(res.result.userInfo));
        notification.success({
          message: "สำเร็จ",
          description: "ยินดีต้อนรับเข้าสู่ระบบ",
          duration: 1.5,
        });
        history.push("/dashboard");
      } else {
        notification.warning({
          message: "เข้าสู่ระบบไม่สำเร็จ",
          description:
            "รหัสผ่าน หรือ ชื่อผู้ใช้ ไม่ถูกต้อง! กรุณาลองใหม่อีกครั้ง",
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์",
        description: "ตรวจสอบการเชื่อมต่อแล้วลองใหม่อีกครั้ง",
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isMobile ? (
        <LoginMobile />
      ) : (
        <div>
          <Row>
            <Col span={14}>
              <div className="login-page">
                <Col>
                  <img src={brand} alt="logo" width={200} />
                </Col>
                <Col className="img-backdrop">
                  <img src={backdrop} alt="backdrop" style={{ width: "50%" }} />
                </Col>
                <Col className="text-login">
                  <h1>MSM-MUANGTHONG</h1>
                  <p>
                    Become the most trusted, respected and successful property
                  </p>
                  <p>management organization in Thailand</p>
                </Col>
              </div>
            </Col>
            <Col span={10}>
              <div className="form-login">
                <div className="bg-page-login">
                  <Form name="basic" onFinish={onFinish} form={form}>
                    <Row>
                      <Col className="text-title-login">
                        <h2>Welcome</h2>
                        <p>Log in to your account to continue</p>
                      </Col>
                      <Col md={24}>
                        <Form.Item
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Username"
                            prefix={
                              <img src={user} alt="user" width={25}></img>
                            }
                            className="input-line"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24}>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="Password"
                            prefix={<img src={key} alt="key" width={25}></img>}
                            className="input-line"
                          />
                        </Form.Item>
                      </Col>
                      <div className="tab-fogot">
                        <Form.Item name="remember" valuePropName="checked">
                          <Checkbox className="checkbox-cutom">
                            Remember
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                          <p>Reset Password</p>
                        </Form.Item>
                      </div>
                      <Col md={24}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            block
                            loading={loading}
                            className="btn-block"
                          >
                            SIGN IN
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default LoginPage;
