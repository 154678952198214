import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import brand from "@brand/MSM.png";

import user from "@icon/user2.svg";
import key from "@icon/key.svg";
import { userLogin, userLoginAPT } from "@api/auth";
import { resResult } from "@tools/constain";
import { useHistory } from "react-router-dom";

const LoginMobile = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    localStorage.clear();
    // eslint-disable-next-line
  }, []);
  const onFinish = async (valuse: userLogin) => {
    try {
      setLoading(true);
      const res = await userLoginAPT(valuse);
      if (res.code === resResult.sucess) {
        localStorage.setItem("token", res.result.accessToken);
        localStorage.setItem("refreshToken", res.result.refreshToken);

        history.push("/dashboard");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      <Row justify="center">
        <Col>
          <div className="login-page-mobile">
            <img src={brand} alt="logo" width={200} />
          </div>
        </Col>
        <Col>
          <div className="bg-page-login-mobile">
            <Form name="basic" onFinish={onFinish} form={form}>
              <Row>
                <Col className="text-title-login-mobile">
                  <h2>Welcome</h2>
                  <p>Log in to your account to continue</p>
                </Col>
                <Col md={24} xs={24}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Username"
                      prefix={<img src={user} alt="user"></img>}
                      className="input-line"
                    />
                  </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      prefix={<img src={key} alt="key"></img>}
                      className="input-line"
                    />
                  </Form.Item>
                </Col>

                <Col md={24} xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loading}
                      className="btn-block-white"
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Row justify="center">
            <Col className="text-login-mobile">
              <h1>MSM-MUANGTHONG</h1>
              <p>Become the most trusted, respected and successful property</p>
              <p>management organization in Thailand</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LoginMobile;
